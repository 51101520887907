import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./GetStartedDeathVerification.scss";
import useGlobal from "../../../../store";
import history from "./../../../common/history";
import Progress from "../../Progress";
import { useTranslation, Trans } from "react-i18next";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import MaskedInput from "react-maskedinput";
import { isMobile, isMobileOnly } from "react-device-detect";
import { TxAlert, TxRadioGroup } from "texkit-ui";

function DeathVerification(props) {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();
  const [showAlert, setShowAlert] = useState(false);
  const [deliveryType, setDeliveryType] = useState(null);

  const stateList = props.stateList;

  const SignupSchema = yup.object().shape({
    dateOfDeath: yup
      .date()
      .required(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE"))
      .min(new Date("1903-01-01"), t("DATE_COMMON_ERRORS.VERIFY_DATE_MIN"))
      .max(new Date(), t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE_MAX"))
      .typeError(t("DATE_COMMON_ERRORS.VERIFY_DATE_RANGE")),

    validateState: yup.boolean(
      globalState.verifyIdentityData.idVerified === "Y" ? true : false
    ),

    selectedState: yup.object().when("validateState", {
      is: (validateState) => validateState === false,
      then: yup.object().shape({
        label: yup
          .string()
          .typeError(t("GET_STARTED_DEATHCERTIFICATE.STATE_ERROR"))
          .required(t("GET_STARTED_DEATHCERTIFICATE.STATE_ERROR")),

        value: yup
          .string()
          .typeError(t("GET_STARTED_DEATHCERTIFICATE.STATE_ERROR"))
          .required(t("GET_STARTED_DEATHCERTIFICATE.STATE_ERROR")),
      }),
    }),
  });

  const { handleSubmit, control, errors } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(SignupSchema),
  });

  const onSubmit = (data) => {
    if (deliveryType === "Online") {
      globalActions.setIsOnlineOrder(true);
    }
    globalActions.getStarteDeathVerificationInfo(data);
    if (
      globalState.verifyIdentityData.idVerified !== undefined &&
      globalState.verifyIdentityData.idVerified === "Y"
    ) {
      props.nextCallback(3);
    } else {
      data.selectedState.value === "TX"
        ? props.nextCallback()
        : props.nextCallback(2);
    }
  };

  const isValidOnlineOrder = () => {
    if (!globalState.totalOrderRecords || globalState.totalOrderRecords.length === 0) {
      return true;
    } else if ((globalState.totalOrderRecords.length === 1) &&
       (globalState.saveEditIndex.referenceType === globalState.totalOrderRecords[0].certificateInfoType)) {
      return true;
    }
    return false;
  }

  return (
    <div>
      <Progress currentStep={1} totalSteps={4} />
      <h1 className="deathverification-heading">
        {t("DEATH_VERIFICATION.HEADING")}
      </h1>
      <p className="deathverification-content">
        {t("DEATH_VERIFICATION.DESCRIPTION")}
      </p>
      <form
        className="deathverification-content-section"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="deathverification-div">
          <label className="deathverification-info">
            {t("DEATH_VERIFICATION.GET_STARTED")}
          </label>

          <label className="deathverification-info1">
            {t("DEATH_VERIFICATION.GET_STARTED_INFO")}
          </label>
          <div className="deathVerfiyDateRange">
            {(globalState.verifyIdentityData.idVerified === undefined ||
              globalState.verifyIdentityData.idVerified === "" ||
              globalState.verifyIdentityData.idVerified === "N") && (
              <>
                <label className="birth-content ">
                  {t("COMMONS.CHOOSE_STATE")}
                
                <div className="select-align">
                  <Controller
                    name="selectedState"
                    className={`get-state-birth1 ${
                      errors.selectedState ? "error-red" : ""
                    }`}
                    as={Select}
                    options={stateList}
                    control={control}
                    isClearable
                    defaultValue={
                      globalState.getStarteDeathVerificationInfo.selectedState
                        .label
                        ? globalState.getStarteDeathVerificationInfo
                            .selectedState
                        : ""
                    }
                    rules={{ required: true }}
                  />
                  {errors.selectedState && (
                    <p className="error-red-content">
                      {t("GET_STARTED_BIRTHCERTIFICATE.STATE_ERROR")}
                    </p>
                  )}
                </div>
              </label>
              </>
            )}
            <label htmlFor="dateOfDeath" className="deathverification-info1">
              {t("DEATH_VERIFICATION.GET_STARTED_TYPE")}
            </label>
            <div className="DoD-Range">
              <Controller
                control={control}
                name="dateOfDeath"
                rules={{ required: true }}
                defaultValue={
                  globalState.getStarteDeathVerificationInfo.dateOfDeath
                    ? globalState.getStarteDeathVerificationInfo.dateOfDeath
                    : ""
                }
                className="input-date"
                render={({ onChange, onBlur, value }) => (
                  <ReactDatePicker
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    id="dateOfDeath"
                    className={`input-date get-state-birth ${
                      errors.dateOfDeath ? "error-red" : ""
                    }`}
                    showMonthDropdown
                    showYearDropdown
                    open={isMobile ? false : undefined}
                    customInput={<MaskedInput mask="11/11/1111" />}
                    placeholderText="00/00/0000"
                  />
                )}
              />
              {errors.dateOfDeath && (
                <p className="error-red-content">
                  {errors.dateOfDeath.message}
                </p>
              )}
            </div>

            <label className="deathverification-delivery-type">
              {t("ONLINE_ORDER.HEADING_DELIVERY_TYPE")}
            </label>

            <TxRadioGroup
              options={[
                {
                  label: t("ONLINE_ORDER.ONLINE"),
                  value: "Online",
                },
                {
                  label: t("ONLINE_ORDER.MAIL"),
                  value: "Mail",
                },
              ]}
              onChange={(e) => {
                if (e.target.value === "Online") {
                  if (!isValidOnlineOrder()) {
                    setShowAlert(true);
                  } else {
                    setDeliveryType(e.target.value);
                  }
                }
                if (e.target.value === "Mail") {
                  setShowAlert(false);
                  setDeliveryType(e.target.value);
                }
                return;
              }}
            />

            {showAlert && (
              <TxAlert
                heading={t("ONLINE_ORDER.ALERT_HEADING")}
                children={<div>
                  <ul>
                    <Trans 
                      i18nKey="ONLINE_ORDER.ALERT_BODY"
                      components={[
                        <p />,
                        <li />,
                        <li />
                      ]}
                    />
                  </ul>
                </div>}
                className="online-alert"
              />
            )}
            
            <Row className="btn-group-align" sm={1} xs={1} md={2} lg={2}>
              <Col>
                <Button
                  variant="outline-primary"
                  className="back-button"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push(t("URLS.SELECT_VITAL_RECORD"));
                  }}
                >
                  {t("COMMONS.BACK")}
                </Button>
              </Col>
              <Col xs={{ order: isMobileOnly ? "first" : "last" }}>
                <Button disabled={!deliveryType || showAlert} variant="primary" className="next-button" type="submit">
                  {t("COMMONS.NEXT")}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </form>
    </div>
  );
}
export default DeathVerification;
